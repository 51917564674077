// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
@media (min-width: 992px){
  div{
    .modal-xl {
        max-width: 100%;
    }
  }
}
li.nav-item {
  line-height: 1.2;
}
.hidden {
  display: none;
}
.modal-75 {
  max-width: 75% !important;
}
.modal-45 {
  max-width: 45% !important;
}
.modal-breakout .modal-title {
  margin: auto;
}
body{
  .sidebar{
    background: #013158;
    .nav-link.active {
      background: #024b82;
    }
    button.sidebar-minimizer.mt-auto {
      background: #022140;
    }
  }
  .breadcrumb-item.active {
    color: #bd0503;
  }
  @media screen and (max-width: 500px){
    .main .container-fluid{
      padding: 0px 5px;
    }
  }
  .pagination {
    overflow: auto;
  }
  .table-striped{
    tbody{
      tr.current-status-0 {
        background-color: #E3F2FD;
      }
      tr.current-status-1, .passed-test {
        background-color: #e8f5e9;
      }
      tr.current-status-2, .failed-test {
        background-color: #fbe9e7;
      }
    }
  }
}
@media screen and (min-width: 980px){
  .neg-margin-30-desktop{
    margin-left: -30px;
    margin-right: 0px;
  }
}
.failed-test, .passed-test {
  padding: 0px 9px;
}
button.btn.btn-outline-danger.btn-square.btn-block.white-bg-default.btn.btn-secondary.btn-sm {
  background: #FFFFFF;
}
button.btn.btn-outline-danger.btn-square.btn-block.white-bg-default.btn.btn-secondary.btn-sm:hover {
  background: #f86c6b;
}
.inline-flex {
  display: inline-flex;
  overflow: auto;
  scroll-behaviour: smooth;
}
